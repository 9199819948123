import logo from './logo.svg';
import tlogo from './Coloforllogo.png'
import './App.css';
import event from './Events.png'
import fsi from './naomi-tamar-CZdYRHVDXlk-unsplash.jpg'
import IconButton from './components/iconbutton';
import ps from './logo-google-playstore.svg'
import as from './logo-apple-appstore.svg'
import wb from './laptop-outline.svg'

function App() {
  return (
    <div className="App">
<div className='navbar'>
  <span className='logo'><img src={event}/></span>
  <ul>
    <li><a>Pourquoi Events</a></li>
    <li><a>Découvrir</a></li>
    <li><a>Créer un évenement</a></li>
    <li><a>Aide</a></li>
  </ul>
</div>

<div className='hero'>
  <span className='htc'>
    <h3>Rassemblez le monde autour de la musique</h3>
    <h4>Partagez des moments exceptionnels avec votre communauté a travers vos evenemenst musicaux.</h4>
    <span className='cta'>Créer un évenement</span>
  </span>
  <span className='hic'>
    <div className='card div1'>Concerts</div>
    <div className='card div2'>Show case</div>
    <div className='card div3'>Ecoutes privées</div>
    <div className='card div4'>Dédicaces</div>
  </span>
</div>

  <h3 className='why-h3'>Pourquoi Event ?</h3>
<div className='why'>

<div className="msc">
  <div className='amc'>
  <div className='imc'><img/></div>
  <div className ='tc'>
    <span>Distribute your podcast to the most popular listening apps, including Spotify with just a single tap—and host unlimited content completely free, forever.</span>
  </div>
  </div>

    <div className='amc'>
  <div className ='tc'>
    <span>Distribute your podcast to the most popular listening apps, including Spotify with just a single tap—and host unlimited content completely free, forever.</span>
  </div>
  <div className='imc'><img/></div>
  </div>

</div>

<div className="msc">
  <div className='amc'>
  <div className='imc'><img/></div>
  <div className ='tc'>
    <span>Distribute your podcast to the most popular listening apps, including Spotify with just a single tap—and host unlimited content completely free, forever.</span>
  </div>
  </div>

    <div className='amc'>
  <div className ='tc'>
    <span>Distribute your podcast to the most popular listening apps, including Spotify with just a single tap—and host unlimited content completely free, forever.</span>
  </div>
  <div className='imc'><img/></div>
  </div>

</div>

</div>

<h3 className='why-h3'>Créez facilement vos evenements</h3>
<div className="create">

<div className='stc'>
<div className="ilc"><img/></div>
  <div className ='otc'>
    <span>Distribute your podcast to the most popular listening apps, including Spotify with just a single tap—and host unlimited content completely free, forever.</span>
  </div>
</div>

<div className='stc'>
<div className="ilc"><img/></div>
  <div className ='otc'>
    <span>Distribute your podcast to the most popular listening apps, including Spotify with just a single tap—and host unlimited content completely free, forever.</span>
  </div>
</div>

<div className='stc'>
<div className="ilc"><img/></div>
  <div className ='otc'>
    <span>Distribute your podcast to the most popular listening apps, including Spotify with just a single tap—and host unlimited content completely free, forever.</span>
  </div>
</div>

<div className='stc'>
<div className="ilc"><img/></div>
  <div className ='otc'>
    <span>Distribute your podcast to the most popular listening apps, including Spotify with just a single tap—and host unlimited content completely free, forever.</span>
  </div>
</div>
</div>

<div className='scta'>
  <h3 className='create-h3'>Pret à créer votre premier evenement sur colorfol Event ?</h3>
  <span className='secta'>Allons-y !</span>
</div>

<div className='fw'>
                    <svg viewBox="0 -20 700 110" width="100%" height="110" preserveAspectRatio="none">
                        <path transform="translate(0, -20)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#4b7e40" />
                        <path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="#37702b" /></svg>

                </div>
<div className='footer'>
<span className='ftr-logo'><img src={tlogo} alt="floatings-icons" width='148' height='72'/></span>
<ul className='ftr-content'>
  <li><h3>Services</h3></li>
  <li><a>Streaming Musique et Podcasts</a></li>
  <li><a>Streaming Video</a></li>
  <li><a>Events</a></li>
  <li><a>Network Musical</a></li>
  <li><a>Reviews</a></li>
</ul>

<ul className='ftr-content'>
  <li><h3>Contacts</h3></li>
  <li><a>contact@colorfol.com</a></li>
  <li><a>(+237) 650 26 26 35</a></li>
  <li><a>Akwa - Douala - Cameroun</a></li>
  <li><a>Network Musical</a></li>
  <li><a> </a></li>
  <li><a> </a></li>
</ul>

<ul className='ftr-content'>
  <li><h3>Obtenez l'app</h3></li>
  <li>
  <IconButton title='Play Store' icon={ps}/>
      </li>
      <li>
      <IconButton title='Apple Store' icon={as}/>
     </li>
     <li>
     <IconButton title='Web player' icon={wb}/>
     </li>
</ul>
</div>
    </div>
  );
}

export default App;
